const allLang = {
  home: {
    "zh-cn": "首页",
    "en-gb": "HOME",
    "de-de": "STARTSEITE",
    "fr-fr": "ACCUEIL",
    "ru": "Главная страница",
    "nl": "HOME",
    "pl": "Strona główna",
    "ja": "ホームページ",
  },
  news: {
    "zh-cn": "新闻",
    "en-gb": "NEWS",
    "de-de": "NACHRICHTEN",
    "fr-fr": "ACTUALITÉS",
    "ru": "Новости",
    "nl": "NIEUWS",
    "pl": "Wiadomości",
    "ja": "ニュース",
  },
  games: {
    "zh-cn": "游戏",
    "en-gb": "GAMES",
    "de-de": "SPIELE",
    "fr-fr": "JEUX",
    "ru": "Игры",
    "nl": "GAMES",
    "pl": "Gry",
    "ja": "ゲーム",
  },

  publish: {
    "zh-cn": "发行",
    "en-gb": "PUBLISH",
    "de-de": "VERÖFFENTLICHEN",
    "fr-fr": "PUBLIER",
    "ru": "Выпуск игр",
    "nl": "UITGEVEN",
    "pl": "Publikacje",
    "ja": "パブリッシャー",
  },

  about: {
    "zh-cn": "关于我们",
    "en-gb": "ABOUT",
    "de-de": "ÜBER UNS",
    "fr-fr": "À PROPOS DE NOUS",
    "ru": "О нас",
    "nl": "OVER ONS",
    "pl": "O nas",
    "ja": "会社概要",
  },

  partner: {
    "zh-cn": "合作伙伴",
    "en-gb": "PARTNER",
    "de-de": "PARTNER",
    "fr-fr": "PARTENAIRE",
    "ru": "Партнёры",
    "nl": "PARTNER",
    "pl": "Partnerzy",
    "ja": "提携関係",
  },
  more: {
    "zh-cn": "更多",
    "en-gb": "MORE",
    "de-de": "MEHR",
    "fr-fr": "PLUS",
    "ru": "Ещё",
    "nl": "MEER",
    "pl": "Więcej",
    "ja": "その他",
  },



  ourgame: {
    "zh-cn": "我们的游戏",
    "en-gb": "Our Games",
    "de-de": "UNSER SPIEL",
    "fr-fr": "NOS JEUX",
    "ru": "Наши игры",
    "nl": "ONZE GAME",
    "pl": "Nasze gry",
    "ja": "ゲームコンテンツ",
  },

  browsemoregames: {
    "zh-cn": "浏览更多的游戏",
    "en-gb": "BROWSE MORE GAMES",
    "de-de": "MEHR SPIELE ANZEIGEN",
    "fr-fr": "CONSULTEZ PLUS DE JEUX",
    "ru": "Посмотреть больше игр",
    "nl": "MEER GAMES BEKIJKEN",
    "pl": "Zobacz więcej gier",
    "ja": "ゲームコンテンツをもっと見る",
  },

  aboutthe: {
    "zh-cn": "关于",
    "en-gb": "ABOUT",
    "de-de": "ÜBER ",
    "fr-fr": "À PROPOS DE ",
    "ru": "О компании ",
    "nl": "OVER ",
    "pl": "O ",
    "ja": "	について",
  },

  east2west: {
    "zh-cn": "东品游戏",
    "en-gb": "EAST2WEST",
    "de-de": "East2West Games",
    "fr-fr": "EAST2WEST",
    "ru": "East2West Games",
    "nl": "EAST2WEST",
    "pl": "East2West Games",
    "ja": "East2West Games",
  },

  steampublishing: {
    "zh-cn": "STEAM\n发行",
    "en-gb": "STEAM\nPUBLISHING",
    "de-de": "VERÖFFENTLICHUNG VON\nSTEAM",
    "fr-fr": "PUBLICATION\nSTEAM",
    "ru": "Выпуск игр для\nSTEAM",
    "nl": "STEAM\nUITGAVEN",
    "pl": "Publikacje na\nSteam",
    "ja": "STEAM\nでのパブリッシング",
  },

  ourservice: {
    "zh-cn": "我们的服务",
    "en-gb": "OUR SERVICE",
    "de-de": "UNSERE DIENSTLEISTUNGEN",
    "fr-fr": "NOTRE SERVICE",
    "ru": "Наши услуги",
    "nl": "ONZE SERVICE",
    "pl": "Nasze usługi",
    "ja": "サービス案内",
  },

  ourpartner: {
    "zh-cn": "我们的合作伙伴",
    "en-gb": "OUR PARTNER",
    "de-de": "UNSERE PARTNER",
    "fr-fr": "NOS PARTENAIRES",
    "ru": "Наши партнёры",
    "nl": "ONZE PARTNER",
    "pl": "Nasi Partnerzy",
    "ja": "私達のパートナー",
  },
  developers: {
    "zh-cn": "和我们合作的开发者",
    "en-gb": "The developers we work with",
    "de-de": "MIT UNS KOOPERIERENDE ENTWICKLER",
    "fr-fr": "Développeurs avec lesquels nous travaillons",
    "ru": "Мы сотрудничаем с нашими партнёрами в течение",
    "nl": "Ontwikkelaars waarmee we samenwerken",
    "pl": "Developerzy, z którymi pracujemy",
    "ja": "提携関係のデベロッパー",
  },

  copyright: {
    "zh-cn": "西安西品网络科技有限公司",
    "en-gb": "East2west Network Tech. Co. Ltd.",
    "de-de": "East2west Network Tech. Co. Ltd.",
    "fr-fr": "East2west Network Tech. Co. Ltd.",
    "ru": "East2west Network Tech. Co. Ltd.",
    "nl": "East2west Network Tech. Co. Ltd.",
    "pl": "East2west Network Tech. Co. Ltd.",
    "ja": "East2west Network Tech. Co. Ltd.",
  },

  submityourgame: {
    "zh-cn": "上传游戏",
    "en-gb": "SUBMIT YOUR GAME",
    "de-de": "SUBMIT YOUR GAME",
    "fr-fr": "SUBMIT YOUR GAME",
    "ru": "SUBMIT YOUR GAME",
    "nl": "SUBMIT YOUR GAME",
    "pl": "SUBMIT YOUR GAME",
    "ja": "SUBMIT YOUR GAME",
  },

  newsletter: {
    "zh-cn": "资讯",
    "en-gb": "NEWSLETTER",
    "de-de": "NEWSLETTER",
    "fr-fr": "NEWSLETTER",
    "ru": "NEWSLETTER",
    "nl": "NEWSLETTER",
    "pl": "NEWSLETTER",
    "ja": "NEWSLETTER",
  },

  overseasexhibits: {
    "zh-cn": "海外展",
    "en-gb": "OVERSEAS EXHIBITS",
    "de-de": "OVERSEAS EXHIBITS",
    "fr-fr": "OVERSEAS EXHIBITS",
    "ru": "OVERSEAS EXHIBITS",
    "nl": "OVERSEAS EXHIBITS",
    "pl": "OVERSEAS EXHIBITS",
    "ja": "OVERSEAS EXHIBITS",
  },

  domesticexhibits: {
    "zh-cn": "国内展",
    "en-gb": "DOMESTIC EXHIBITS",
    "de-de": "DOMESTIC EXHIBITS",
    "fr-fr": "DOMESTIC EXHIBITS",
    "ru": "DOMESTIC EXHIBITS",
    "nl": "DOMESTIC EXHIBITS",
    "pl": "DOMESTIC EXHIBITS",
    "ja": "DOMESTIC EXHIBITS",
  },

  mediareport: {
    "zh-cn": "媒体报道",
    "en-gb": "MEDIA REPORT",
    "de-de": "MEDIA REPORT",
    "fr-fr": "MEDIA REPORT",
    "ru": "MEDIA REPORT",
    "nl": "MEDIA REPORT",
    "pl": "MEDIA REPORT",
    "ja": "MEDIA REPORT",
  },

  winninghonoer: {
    "zh-cn": "荣誉",
    "en-gb": "WINNING HONOER",
    "de-de": "WINNING HONOER",
    "fr-fr": "WINNING HONOER",
    "ru": "WINNING HONOER",
    "nl": "WINNING HONOER",
    "pl": "WINNING HONOER",
    "ja": "WINNING HONOER",
  },

  thedetailedcontent: {
    "zh-cn": "查看更多",
    "en-gb": "THE DETAILED CONTENT",
    "de-de": "THE DETAILED CONTENT",
    "fr-fr": "THE DETAILED CONTENT",
    "ru": "THE DETAILED CONTENT",
    "nl": "THE DETAILED CONTENT",
    "pl": "THE DETAILED CONTENT",
    "ja": "THE DETAILED CONTENT",
  },

  allgame: {
    "zh-cn": "所有游戏",
    "en-gb": "ALL GAME",
    "de-de": "ALL GAME",
    "fr-fr": "ALL GAME",
    "ru": "ALL GAME",
    "nl": "ALL GAME",
    "pl": "ALL GAME",
    "ja": "ALL GAME",
  },

  pcgames: {
    "zh-cn": "PC平台",
    "en-gb": "PC GAMES",
    "de-de": "PC GAMES",
    "fr-fr": "PC GAMES",
    "ru": "PC GAMES",
    "nl": "PC GAMES",
    "pl": "PC GAMES",
    "ja": "PC GAMES",
  },
  androidgames: {
    "zh-cn": "安卓平台",
    "en-gb": "ANDROID GAMES",
    "de-de": "ANDROID GAMES",
    "fr-fr": "ANDROID GAMES",
    "ru": "ANDROID GAMES",
    "nl": "ANDROID GAMES",
    "pl": "ANDROID GAMES",
    "ja": "ANDROID GAMES",
  },
  iosgames: {
    "zh-cn": "iOS平台",
    "en-gb": "iOS GAMES",
    "de-de": "iOS GAMES",
    "fr-fr": "iOS GAMES",
    "ru": "iOS GAMES",
    "nl": "iOS GAMES",
    "pl": "iOS GAMES",
    "ja": "iOS GAMES",
  },

  consolesgames: {
    "zh-cn": "主机",
    "en-gb": "CONSOLES GAMES",
    "de-de": "CONSOLES GAMES",
    "fr-fr": "CONSOLES GAMES",
    "ru": "CONSOLES GAMES",
    "nl": "CONSOLES GAMES",
    "pl": "CONSOLES GAMES",
    "ja": "CONSOLES GAMES",
  },
  explore: {
    "zh-cn": "更多",
    "en-gb": "EXPLORE",
    "de-de": "EXPLORE",
    "fr-fr": "EXPLORE",
    "ru": "EXPLORE",
    "nl": "EXPLORE",
    "pl": "EXPLORE",
    "ja": "EXPLORE",
  },
  partneredstudios: {
    "zh-cn": "60余家合作工作室",
    "en-gb": ">60 Partnered Studios",
    "de-de": ">60 Partnered Studios",
    "fr-fr": ">60 Partnered Studios",
    "ru": ">60 Partnered Studios",
    "nl": ">60 Partnered Studios",
    "pl": ">60 Partnered Studios",
    "ja": ">60 Partnered Studios",
  },
  stores: {
    "zh-cn": "商店",
    "en-gb": "STORES",
    "de-de": "STORES",
    "fr-fr": "STORES",
    "ru": "STORES",
    "nl": "STORES",
    "pl": "STORES",
    "ja": "STORES",
  },
  favouritestore: {
    "zh-cn": "选择您最喜欢的商店购买游戏",
    "en-gb": "Purchase a game on your favourite store",
    "de-de": "Purchase a game on your favourite store",
    "fr-fr": "Purchase a game on your favourite store",
    "ru": "Purchase a game on your favourite store",
    "nl": "Purchase a game on your favourite store",
    "pl": "Purchase a game on your favourite store",
    "ja": "Purchase a game on your favourite store",
  },
  gamedetails: {
    "zh-cn": "游戏详情",
    "en-gb": "GAME DETAILS",
    "de-de": "GAME DETAILS",
    "fr-fr": "GAME DETAILS",
    "ru": "GAME DETAILS",
    "nl": "GAME DETAILS",
    "pl": "GAME DETAILS",
    "ja": "GAME DETAILS",
  },
  platforms: {
    "zh-cn": "平台",
    "en-gb": "PLATFORMS",
    "de-de": "PLATFORMS",
    "fr-fr": "PLATFORMS",
    "ru": "PLATFORMS",
    "nl": "PLATFORMS",
    "pl": "PLATFORMS",
    "ja": "PLATFORMS",
  },
  genre: {
    "zh-cn": "类别",
    "en-gb": "GENRE",
    "de-de": "GENRE",
    "fr-fr": "GENRE",
    "ru": "GENRE",
    "nl": "GENRE",
    "pl": "GENRE",
    "ja": "GENRE",
  },
  developers: {
    "zh-cn": "开发商",
    "en-gb": "DEVELOPERS",
    "de-de": "DEVELOPERS",
    "fr-fr": "DEVELOPERS",
    "ru": "DEVELOPERS",
    "nl": "DEVELOPERS",
    "pl": "DEVELOPERS",
    "ja": "DEVELOPERS",
  },

  screenshots: {
    "zh-cn": "截图",
    "en-gb": "SCREENSHOTS",
    "de-de": "SCREENSHOTS",
    "fr-fr": "SCREENSHOTS",
    "ru": "SCREENSHOTS",
    "nl": "SCREENSHOTS",
    "pl": "SCREENSHOTS",
    "ja": "SCREENSHOTS",
  },

  werecommended: {
    "zh-cn": "官方推荐",
    "en-gb": "We Recommended",
    "de-de": "We Recommended",
    "fr-fr": "We Recommended",
    "ru": "We Recommended",
    "nl": "We Recommended",
    "pl": "We Recommended",
    "ja": "We Recommended",
  },

  developer: {
    "zh-cn": "开发者",
    "en-gb": "Developer",
    "de-de": "Developer",
    "fr-fr": "Developer",
    "ru": "Developer",
    "nl": "Developer",
    "pl": "Developer",
    "ja": "Developer",
  },

  chinesename: {
    "zh-cn": "中文名字",
    "en-gb": "Chinese Name",
    "de-de": "Chinese Name",
    "fr-fr": "Chinese Name",
    "ru": "Chinese Name",
    "nl": "Chinese Name",
    "pl": "Chinese Name",
    "ja": "Chinese Name",
  },

  englishname: {
    "zh-cn": "英文名字",
    "en-gb": "English Name",
    "de-de": "English Name",
    "fr-fr": "English Name",
    "ru": "English Name",
    "nl": "English Name",
    "pl": "English Name",
    "ja": "English Name",
  },

  downloadaddress: {
    "zh-cn": "下载地址",
    "en-gb": "Download Address",
    "de-de": "Download Address",
    "fr-fr": "Download Address",
    "ru": "Download Address",
    "nl": "Download Address",
    "pl": "Download Address",
    "ja": "Download Address",
  },

  gameintroduction: {
    "zh-cn": "游戏简介",
    "en-gb": "Game Introduction",
    "de-de": "Game Introduction",
    "fr-fr": "Game Introduction",
    "ru": "Game Introduction",
    "nl": "Game Introduction",
    "pl": "Game Introduction",
    "ja": "Game Introduction",
  },

  submitscreenshot: {
    "zh-cn": "截图",
    "en-gb": "Screenshot",
    "de-de": "Screenshot",
    "fr-fr": "Screenshot",
    "ru": "Screenshot",
    "nl": "Screenshot",
    "pl": "Screenshot",
    "ja": "Screenshot",
  },

  gamevideolink: {
    "zh-cn": "游戏视频链接",
    "en-gb": "Game Video Link",
    "de-de": "Game Video Link",
    "fr-fr": "Game Video Link",
    "ru": "Game Video Link",
    "nl": "Game Video Link",
    "pl": "Game Video Link",
    "ja": "Game Video Link",
  },

  steamlink: {
    "zh-cn": "Steam 链接",
    "en-gb": "Steam Link",
    "de-de": "Steam Link",
    "fr-fr": "Steam Link",
    "ru": "Steam Link",
    "nl": "Steam Link",
    "pl": "Steam Link",
    "ja": "Steam Link",
  },

  publicdisklink: {
    "zh-cn": "公共磁盘链接",
    "en-gb": "Public Disk Link",
    "de-de": "Public Disk Link",
    "fr-fr": "Public Disk Link",
    "ru": "Public Disk Link",
    "nl": "Public Disk Link",
    "pl": "Public Disk Link",
    "ja": "Public Disk Link",
  },

  review: {
    "zh-cn": "评价",
    "en-gb": "Review",
    "de-de": "Review",
    "fr-fr": "Review",
    "ru": "Review",
    "nl": "Review",
    "pl": "Review",
    "ja": "Review",
  },


  email: {
    "zh-cn": "邮箱",
    "en-gb": "Email",
    "de-de": "Email",
    "fr-fr": "Email",
    "ru": "Email",
    "nl": "Email",
    "pl": "Email",
    "ja": "Email",
  },

  submit: {
    "zh-cn": "提交",
    "en-gb": "SUBMIT",
    "de-de": "SUBMIT",
    "fr-fr": "SUBMIT",
    "ru": "SUBMIT",
    "nl": "SUBMIT",
    "pl": "SUBMIT",
    "ja": "SUBMIT",
  },

  yourgame: {
    "zh-cn": "你的游戏",
    "en-gb": "YOUR GAME",
    "de-de": "YOUR GAME",
    "fr-fr": "YOUR GAME",
    "ru": "YOUR GAME",
    "nl": "YOUR GAME",
    "pl": "YOUR GAME",
    "ja": "YOUR GAME",
  },

  uploadinformation: {
    "zh-cn": "如果您无法在线上传信息，请将您的游戏信息发送至 zmk@east2west.cn 非常感谢!",
    "en-gb": "If you cannot upload information online, please send your game information to zmk@east2west.cn. Thank you!",
    "de-de": "If you cannot upload information online, please send your game information to zmk@east2west.cn. Thank you!",
    "fr-fr": "If you cannot upload information online, please send your game information to zmk@east2west.cn. Thank you!",
    "ru": "If you cannot upload information online, please send your game information to zmk@east2west.cn. Thank you!",
    "nl": "If you cannot upload information online, please send your game information to zmk@east2west.cn. Thank you!",
    "pl": "If you cannot upload information online, please send your game information to zmk@east2west.cn. Thank you!",
    "ja": "If you cannot upload information online, please send your game information to zmk@east2west.cn. Thank you!",
  },

  selectfile: {
    "zh-cn": "选择文件",
    "en-gb": "Select File",
    "de-de": "Select File",
    "fr-fr": "Select File",
    "ru": "Select File",
    "nl": "Select File",
    "pl": "Select File",
    "ja": "Select File",
  },
}


export function getLang() {
  var lang = localStorage.getItem("lang");
  if (lang != null) {
    return lang;
  }
  return "en-gb";
}

export function setLang(lang) {
  return localStorage.setItem("lang", lang);
}

export function getBrowserLang() {
  return (navigator.language || navigator.browserLanguage).toLowerCase();

}

export function parseJson(json) {
  if (json == null || json == undefined) {
    return [];
  }
  if (json == "" || json == '[]') {
    return [];
  }
  return JSON.parse(json);
}

export function getText(text, lang) {
  for (var key in allLang) {
    if (key == text) {
      let item = allLang[key];
      for (var i in item) {
        if (i == lang) {
          var reg = new RegExp("\n", "g");
          return item[i].replace(reg, "<br>");;
        }
      }
    }
  }

}


export function getImg(img, lang) {
  for (var key in img) {
    if (img[key].lang == lang) {
      return img[key].img;
    }
  }

}