<template>
  <el-row class="header-bg">
    <el-row class="mobile-header">
      <el-col :md="9" :sm="9" :xs="9" class="logo">
        <a href="/"> <img :src="img(logo, lang)" /></a>
      </el-col>
      <el-col :md="12" :sm="12" :xs="12" class="col-lang">
        <a href="/gamesubmit">
          <button class="submit-game">{{ text("submityourgame", lang) }}</button>
        </a>
      </el-col>
      <el-col :md="3" :sm="3" :xs="3">

        <el-menu mode="horizontal" active-text-color="#009cb1" :default-active="'1-' + activeIndex"
          @select="handleSelect">
          <el-submenu index="1" popper-class="menu-collapse">
            <template slot="title">
              <a href="javascript:" class="line-icon">
                <span></span>
                <span></span>
                <span></span>
              </a>
            </template>
            <el-menu-item index="1-1"><a href="/">{{ text("home", lang) }}</a></el-menu-item>
            <el-menu-item index="1-2"><a href="/news">{{ text("news", lang) }}</a></el-menu-item>
            <el-menu-item index="1-3"><a href="/games">{{ text("games", lang) }}</a></el-menu-item>
            <el-menu-item index="1-4"><a href="/partner">{{ text("partner", lang) }}</a></el-menu-item>
            <el-menu-item index="1-5"><a href="/about">{{ text("about", lang) }}</a></el-menu-item>
            <el-submenu index="1-6" popper-class="sub-menu-collapse">
              <template slot="title">
                <div v-for="(item, index) in Langs" :key="index" v-show="item.lang == lang">
                  <img :src="item.img" class="lang" />
                  {{ item.text }}
                </div>
              </template>
              <el-menu-item v-for="(item, index) in Langs" :key="index" v-show="item.lang != lang" :command="item.lang"
                :index="item.lang">
                <img :src="item.img" />{{ item.text }}
              </el-menu-item>
            </el-submenu>
          </el-submenu>
        </el-menu>

      </el-col>
    </el-row>
    <el-row class="header" justify="center" type="flex">
      <el-col :lg="8" :md="11" :sm="24" :xs="24" class="col-logo">
        <a href="/"> <img :src="img(logo, lang)" /></a>
      </el-col>
      <el-col :lg="8" :md="11" :sm="24" :xs="24" class="col-lang">
        <a href="/gamesubmit">
          <button class="submit-game">{{ text("submityourgame", lang) }}</button>
        </a>
        <el-dropdown @command="handleCommand">
          <div class="el-dropdown-link">
            <img src="@/assets/arrow-bottom.svg" />
          </div>
          <img v-for="(item, index) in Langs" :key="index" v-show="item.lang == lang" :src="item.img" class="lang" />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in Langs" :key="index" v-show="item.lang != lang"
              :command="item.lang"><img :src="item.img" />{{ item.text }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
    <el-row class="nav" justify="center" type="flex">
      <el-col :lg="16" :md="22" :sm="24" :xs="24">
        <el-menu class="el-menu-demo" background-color="#545c64" text-color="#ffffff" active-text-color="#009cb1"
          mode="horizontal" :default-active="activeIndex">
          <el-menu-item index="1"><a href="/">{{ text("home", lang) }}</a></el-menu-item>
          <el-menu-item index="2"><a href="/news">{{ text("news", lang) }}</a></el-menu-item>
          <el-menu-item index="3"><a href="/games">{{ text("games", lang) }}</a></el-menu-item>
          <el-menu-item index="4"><a href="/partner">{{ text("partner", lang) }}</a></el-menu-item>
          <el-menu-item index="5"><a href="/about">{{ text("about", lang) }}</a></el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import { getLang, setLang, getText, getImg } from "@/utils/lang";

export default {
  name: "Header",
  props: {
    activeIndex: String,
  },
  data() {
    return {
      Langs: [
        { img: require("@/assets/zg.svg"), text: "简体中文", lang: "zh-cn" },
        { img: require("@/assets/yg.svg"), text: "English", lang: "en-gb" },
        { img: require("@/assets/dg.svg"), text: "Deutsch", lang: "de-de" },
        { img: require("@/assets/fg.svg"), text: "Francais", lang: "fr-fr" },
        { img: require("@/assets/els.svg"), text: "Pyccknn", lang: "ru" },
        { img: require("@/assets/hl.svg"), text: "Dutch", lang: "nl" },
        { img: require("@/assets/bl.svg"), text: "Polish", lang: "pl" },
        { img: require("@/assets/rb.svg"), text: "Japanese", lang: "ja" },
      ],
      logo: [
        { img: require("@/assets/logo-cn.png"), text: "简体中文", lang: "zh-cn" },
        { img: require("@/assets/logo-en.png"), text: "English", lang: "en-gb" },
        { img: require("@/assets/logo-en.png"), text: "Deutsch", lang: "de-de" },
        { img: require("@/assets/logo-en.png"), text: "Francais", lang: "fr-fr" },
        { img: require("@/assets/logo-en.png"), text: "Pyccknn", lang: "ru" },
        { img: require("@/assets/logo-en.png"), text: "Dutch", lang: "nl" },
        { img: require("@/assets/logo-en.png"), text: "Polish", lang: "pl" },
        { img: require("@/assets/logo-en.png"), text: "Japanese", lang: "ja" },
      ],
      lang: "en-gb",
    };
  },
  methods: {
    handleSelect(key) {
      for (var i in this.Langs) {
        if (this.Langs[i].lang == key) {
          setLang(key);
          this.$router.go(0);
        }
      }
    },
    handleCommand: function (lang) {
      setLang(lang);
      this.$router.go(0);
    },
    img: function (img, lang) {
      return getImg(img, lang);
    },
    text: function (text, lang) {
      return getText(text, lang);
    }
  },
  mounted() {
    this.lang = getLang();
  },
};
</script>

<style scoped>
.mobile-header .line-icon {
  display: inline-block;
}



.mobile-header .line-icon span {
  border-bottom: solid 2px #00b4cc;
  width: 1.2rem;
  padding: 0.15rem 0;
  display: block;
}

.mobile-header {
  padding: 0.8rem 0;
}

.mobile-header .submit-game {
  width: 10rem;
  font-size: 12px;
}

.mobile-header .logo img {
  width: 70%;
}

::v-deep .el-menu--horizontal>.el-menu-item {
  border: none;
}

.el-menu-item {
  padding: 0;
}

.el-menu-item a {
  padding: 0 20px;
  height: 2.5rem;
  line-height: 2.5rem;
  display: block;
}

.el-menu .el-menu-item:last-child a {
  padding-right: 0;
}

.header-bg {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.col-logo {
  text-align: left;
}

.col-logo img {
  width: 35%;
  vertical-align: -webkit-baseline-middle;
}

.submit-game {
  padding: 0.4rem 0;
  cursor: pointer;
  font-size: 0.8rem;
  background-color: #00b4cc;
  border-radius: 0.3rem;
  border: none;
  color: #ffffff;
  font-weight: bold;
  width: 12rem;
}

.el-icon-arrow-down {
  color: #ffffff;
}

.el-dropdown-link {
  display: inline-block;
}

.el-dropdown-link img {
  width: 1.6rem;
  vertical-align: middle;
  margin-left: 1.625rem;
}

.col-lang {
  text-align: right;
}

.lang {
  vertical-align: middle;
  width: 1.6rem;
  margin-left: 0.5rem;
}

.header {
  padding: 1.2rem 0;
}

.el-dropdown-menu {
  background-color: #26282f;
  border: none;
  padding: 0;
}

.el-dropdown-menu__item {
  color: #ffffff;
  border-bottom: solid 1px #1b1d22;
  padding: 0.25rem 2.5rem;
}

.el-dropdown-menu__item img {
  vertical-align: middle;
  margin-right: 1rem;
  width: 1.5rem;
}

.el-menu {
  border: none;
  background-color: transparent !important;
  float: right;
}

.el-menu .el-menu-item:last-child {
  padding-right: 0;
}

.el-menu-item {
  background-color: transparent !important;
  color: #009cb1;
  height: 2.5rem;
  line-height: 2.5rem;
}

.el-menu-item a {
  text-decoration: none;
}

.nav {
  background-color: rgba(0, 0, 0, 0.8);
}

::v-deep .popper__arrow {
  display: none;
}

::v-deep .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
  display: none;
}

::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title {
  height: 22px;
  line-height: 22px;
}

::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
  background-color: transparent;
}

.el-menu--horizontal {}

@media screen and (min-width: 992px) {
  .mobile-header {
    display: none;
  }

  .header {
    display: flex;
  }

  .nav {
    display: flex;
  }
}

@media screen and (max-width: 992px) {
  * {
    flex-wrap: wrap;
  }

  .col-logo {
    text-align: center;
    padding-bottom: 1rem;
  }

  .col-lang {
    text-align: center;
  }

  .mobile-header {
    display: flex;
    align-items: center;
  }

  .header {
    display: none;
  }

  .nav {
    display: none;
  }
}
</style>