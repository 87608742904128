import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/News.vue')
  },
  {
    path: '/newsdetail',
    name: 'newsDetail',
    component: () => import('../views/NewsDetail.vue')
  },
  {
    path: '/games',
    name: 'Games',
    component: () => import('../views/Games.vue')
  },
  {
    path: '/gamedetail',
    name: 'gameDetail',
    component: () => import('../views/GameDetail.vue')
  },
  {
    path: '/gamesubmit',
    name: 'gameSubmit',
    component: () => import('../views/GameSubmit.vue')
  },
  {
    path: '/partner',
    name: 'Partner',
    component: () => import('../views/Partner.vue')
  },
  {
    path: '/partnerlist',
    name: 'PartnerList',
    component: () => import('../views/PartnerList.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
