import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css'
import * as echarts from 'echarts'
import '../node_modules/echarts/map/js/world.js'
import '../public/social-share.js/dist/js/social-share.min.js'
import '../public/social-share.js/dist/css/share.min.css'

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
    Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})


Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.use(ElementUI)

new Vue({
  router,
  store,  
  render: h => h(App)
}).$mount('#app')