<template>
  <div class="home">
    <div class="banner">
      <video class="video" autoplay muted loop ref="videoPlayer" id="playVideos">
      </video>
      <Header activeIndex="1"></Header>
      <el-carousel @change="carouselChange">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <el-row class="banner-info" justify="center" type="flex">
            <el-col :lg="8" :md="11" :sm="22" :xs="22" class="pd">
              <h3>{{ title(item.games) }}</h3>
              <h6>{{ subTitle(item.games) }}</h6>
              <ul class="platform-icon">
                <li v-for="(stores, i) in json(item.games.stores)" :key="i">
                  <img :src="oss + '/' + it.url" v-for="(it, index) in json(stores.icon)" :key="index">
                </li>
              </ul>
              <a :href="['/gamedetail?id=' + item.gameId]">
                <button class="explore">{{ text('explore', lang) }}</button>
              </a>
            </el-col>
            <el-col :lg="8" :md="12" :sm="12" :xs="24">
              <div class="game-img">
                <img :src="oss + '/' + it.url" v-for="(it, index) in json(coverImage(item.games))" :key="index">
              </div>
            </el-col>
          </el-row>
        </el-carousel-item>
      </el-carousel>

    </div>
    <el-row class="news" justify="center" type="flex">
      <el-col :lg="16" :md="22" :sm="22"  :xs="22">
        <el-row>
          <el-col :md="5" class="news-title">
            <span class="title">{{ text("news", lang) }}</span>
            <a href="/news" class="more">{{ text("more", lang) }}<i class="el-icon-arrow-right"></i></a>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-tabs v-model="activeName" @tab-click="tabChange">
              <el-tab-pane :label="text('newsletter', lang)" name="first">
                <el-row class="game-list" :gutter="30">
                  <el-col v-for="(item, index) in news.first" :key="index" :lg="12" :md="22" :sm="22" :xs="22"
                    class="item">
                    <a :href="['/newsdetail?id=' + item.id]">
                      <el-row>
                        <el-col :md="10" :sm="24" :xs="24" class="main-img">
                          <img :src="oss + '/' + it.url" v-for="(it, index) in json(item.imageUrl)" :key="index">
                        </el-col>
                        <el-col :md="14" :sm="22" :xs="22" class="main-text">
                          <div class="title">{{ title(item) }}</div>
                          <div class="date">{{ formatDate(item.releaseDate) }}</div>
                          <ul>
                            <li><img src="@/assets/watch.png" />{{ item.watch }}</li>
                            <li><img src="@/assets/like.png" />{{ item.love }}</li>
                            <li><img src="@/assets/share.png" />{{ item.share }}</li>
                          </ul>
                        </el-col>
                      </el-row>
                    </a>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <el-tab-pane :label="text('overseasexhibits', lang)" name="second">
                <el-row class="game-list" :gutter="30">
                  <el-col v-for="(item, index) in news.second" :key="index" :lg="12" :md="22" :sm="22" :xs="22"
                    class="item">
                    <a :href="['/newsdetail?id=' + item.id]">
                      <el-row>
                        <el-col :md="10" :sm="24" :xs="24" class="main-img">
                          <img :src="oss + '/' + it.url" v-for="(it, index) in json(item.imageUrl)" :key="index">
                        </el-col>
                        <el-col :md="14" :sm="22" :xs="22" class="main-text">
                          <div class="title">{{ title(item) }}</div>
                          <div class="date">{{ formatDate(item.releaseDate) }}</div>
                          <ul>
                            <li><img src="@/assets/watch.png" />{{ item.watch }}</li>
                            <li><img src="@/assets/like.png" />{{ item.love }}</li>
                            <li><img src="@/assets/share.png" />{{ item.share }}</li>
                          </ul>
                        </el-col>
                      </el-row>
                    </a>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <el-tab-pane :label="text('domesticexhibits', lang)" name="third">
                <el-row class="game-list" :gutter="30">
                  <el-col v-for="(item, index) in news.third" :key="index" :lg="12" :md="22" :sm="22" :xs="22"
                    class="item">
                    <a :href="['/newsdetail?id=' + item.id]">
                      <el-row>
                        <el-col :md="10" :sm="24" :xs="24" class="main-img">
                          <img :src="oss + '/' + it.url" v-for="(it, index) in json(item.imageUrl)" :key="index">
                        </el-col>
                        <el-col :md="14" :sm="22" :xs="22" class="main-text">
                          <div class="title">{{ title(item) }}</div>
                          <div class="date">{{ formatDate(item.releaseDate) }}</div>
                          <ul>
                            <li><img src="@/assets/watch.png" />{{ item.watch }}</li>
                            <li><img src="@/assets/like.png" />{{ item.love }}</li>
                            <li><img src="@/assets/share.png" />{{ item.share }}</li>
                          </ul>
                        </el-col>
                      </el-row>
                    </a>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <el-tab-pane :label="text('mediareport', lang)" name="fourth">
                <el-row class="game-list" :gutter="30">
                  <el-col v-for="(item, index) in news.fourth" :key="index" :lg="12" :md="22" :sm="22" :xs="22"
                    class="item">
                    <a :href="['/newsdetail?id=' + item.id]">
                      <el-row>
                        <el-col :md="10" :sm="24" :xs="24" class="main-img">
                          <img :src="oss + '/' + it.url" v-for="(it, index) in json(item.imageUrl)" :key="index">
                        </el-col>
                        <el-col :md="14" :sm="22" :xs="22" class="main-text">
                          <div class="title">{{ title(item) }}</div>
                          <div class="date">{{ formatDate(item.releaseDate) }}</div>
                          <ul>
                            <li><img src="@/assets/watch.png" />{{ item.watch }}</li>
                            <li><img src="@/assets/like.png" />{{ item.love }}</li>
                            <li><img src="@/assets/share.png" />{{ item.share }}</li>
                          </ul>
                        </el-col>
                      </el-row>
                    </a>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <el-tab-pane :label="text('winninghonoer', lang)" name="five">
                <el-row class="game-list" :gutter="30">
                  <el-col v-for="(item, index) in news.five" :key="index" :lg="12" :md="22" :sm="22" :xs="22"
                    class="item">
                    <a :href="['/newsdetail?id=' + item.id]">
                      <el-row>
                        <el-col :md="10" :sm="24" :xs="24" class="main-img">
                          <img :src="oss + '/' + it.url" v-for="(it, index) in json(item.imageUrl)" :key="index">
                        </el-col>
                        <el-col :md="14" :sm="22" :xs="22" class="main-text">
                          <div class="title">{{ title(item) }}</div>
                          <div class="date">{{ formatDate(item.releaseDate) }}</div>
                          <ul>
                            <li><img src="@/assets/watch.png" />{{ item.watch }}</li>
                            <li><img src="@/assets/like.png" />{{ item.love }}</li>
                            <li><img src="@/assets/share.png" />{{ item.share }}</li>
                          </ul>
                        </el-col>
                      </el-row>
                    </a>
                  </el-col>
                </el-row>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="partner" justify="center" type="flex">
      <el-col :lg="16" :md="22" :sm="22" :xs="22">
        <el-row>
          <el-col style="text-align: left">
            <span class="title">{{ text("partner", lang) }}</span>
            <a href="/partner" class="more">{{ text("more", lang) }}<i class="el-icon-arrow-right"></i></a>
          </el-col>
        </el-row>
        <el-row class="partner-link" :gutter="30">
          <el-col :lg="6" :md="6" :sm="22" :xs="22" v-for="(item, index) in partner" :key="index">
            <a :href="item.webSite" target="_blank">
              <img :src="oss + '/' + it.url" v-for="(it, index) in json(item.imageUrl)" :key="index">
            </a>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="ourgame" justify="center" type="flex" align="middle"
      :style="{ 'background-image': 'url(' + ourgameBackground + ')' }">
      <el-col class="ourgamebackcolor">
        <el-row justify="center" type="flex" align="middle">
          <el-col :lg="16" :md="22" :sm="22"  :xs="22">
            <el-row justify="center" type="flex" align="middle">
              <el-col :lg="14" :md="14" :sm="24" :xs="24">
                <el-row :gutter="30">
                  <el-col v-for="(item, index) in ourGames" :key="index" :lg="8" :md="8" :sm="12" :xs="12" class="ourgameimg">
                    <a :href="['/gamedetail?id=' + item.gameId]" @mouseover="ourgameMouseOver(index)">
                      <img :src="oss + '/' + it.url" v-for="(it, index) in json(coverImage(item.games))" :key="index">
                    </a>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :lg="8" :md="10" :sm="24" :xs="24" class="right">
                <el-row>
                  <el-col :lg="22" :md="22" :sm="22"  :xs="22">
                    <div class="game-title">
                      <div class="our">{{ text("ourgame", lang) }}</div>
                      <div class="line"></div>
                    </div>
                    <div v-html="ourgame" class="game-desc">

                    </div>
                    <a href="/games"><button class="more-games">{{ text("browsemoregames", lang) }}</button></a>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-row class="about" justify="center" type="flex">
      <el-col :lg="11" :md="16" :sm="22" :xs="22">
        <div class="text">{{ text("aboutthe", lang) }}</div>
        <el-row>
          <el-col style="text-align: left">
            <span class="title">{{ text("east2west", lang) }}</span>
            <a href="/about" class="more">{{ text("more", lang) }}<i class="el-icon-arrow-right"></i></a>
          </el-col>
        </el-row>
        <div v-html="introduction" class="introduction"></div>
        <img src="@/assets/jqjs2.png" />
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" class="footer-left-line">
        <el-divider class="line"></el-divider>
      </el-col>
      <el-col :span="12" class="footer-right-line">
        <el-divider class="line"></el-divider>
      </el-col>
    </el-row>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import request from "@/utils/request";
import { getLang, getText, parseJson } from "@/utils/lang";
import Config from "@/settings";
import moment from "moment"



export default {
  name: "HomeView",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      activeName: "first",
      oss: "",
      lang: "en-gb",
      introduction: "",
      ourgame: "",
      partner: "",
      type: "1",
      news: {
        first: {},
        second: {},
        third: {},
        fourth: {},
        five: {}
      },
      total: {
        first: 0,
        second: 0,
        third: 0,
        fourth: 0,
        five: 0
      },
      pageSize: 6,
      bannerList: [],
      ourGames: [],
      video: null,
      ourgameBackground: "",
    };
  },


  methods: {
    json(str) {
      return parseJson(str);
    },
    ourgameMouseOver: function (index) {
      var item = this.ourGames[index];
      if (item.imageUrl != null) {
        var imageUrl = this.json(item.imageUrl);
        this.ourgameBackground = this.oss + '/' + imageUrl[0].url;
      }
    },
    handleClick(tab, event) {

    },
    text: function (text, lang) {
      return getText(text, lang);
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    carouselChange: function (index) {
      var item = this.bannerList[index];
      if (item.videoUrl != null) {
        var videoUrl = this.json(item.videoUrl);
        this.video = this.oss + '/' + videoUrl[0].url;
        let vdo = document.getElementById("playVideos")
        vdo.src = this.video;
        vdo.play();
      }
    },

    coverImage: function (item) {
      if (this.lang == "zh-cn") {
        return item.coverImage == undefined ? '[]' : item.coverImage
      } else {
        return item.coverImageEn == undefined ? '[]' : item.coverImageEn
      }
    },

    title: function (item) {
      if (this.lang == "zh-cn") {
        return item.titleCn;
      } else {
        return item.titleEn;
      }
    },
    subTitle: function (item) {
      if (this.lang == "zh-cn") {
        return item.subTitleCn;
      } else {
        return item.subTitleEn;
      }
    },
    pageChange: function (e) {
      this.queryNewsList(e - 1);
    },

    queryHomeBannerList: async function () {
      const params = {
        page: 0,
        size: 9999,
      }
      const res = await request({
        url: "/api/homeBanner/queryHomeBannerList",
        method: "get",
        params: params,
      });
      this.bannerList = res.content;
      this.carouselChange(0);

    },
    tabChange: function (e) {
      var index = parseInt(e.index) + 1;
      this.type = index.toString();
      if (this.type == "1") {
        if (this.news.first.length == undefined) {
          this.queryNewsList(0);
        }
      }
      if (this.type == "2") {
        if (this.news.second.length == undefined) {
          this.queryNewsList(0);
        }
      }
      if (this.type == "3") {
        if (this.news.third.length == undefined) {
          this.queryNewsList(0);
        }
      }
      if (this.type == "4") {
        if (this.news.fourth.length == undefined) {
          this.queryNewsList(0);
        }
      }
      if (this.type == "5") {
        if (this.news.five.length == undefined) {
          this.queryNewsList(0);
        }
      }

    },

    queryHomeOurGameList: async function () {
      const params = {
        page: 0,
        size: 9999,
        sort: 'sort,asc'
      }
      const res = await request({
        url: "/api/homeOurGame/queryHomeOurGameList",
        method: "get",
        params: params,
      });
      this.ourGames = res.content;
      this.ourgameMouseOver(0);

    },
    queryNewsList: async function (page) {
      const res = await request({
        url: "/api/news/queryNewsList?type=" + this.type + "&page=" + page + "&size=" + this.pageSize + "&isShow=1&sort=releaseDate,desc",
        method: "get",
      });
      var result = res.content;
      if (this.type == "1") {
        this.news.first = result;
        this.total.first = res.totalElements;
      }
      if (this.type == "2") {
        this.news.second = result;
        this.total.second = res.totalElements;
      }
      if (this.type == "3") {
        this.news.third = result;
        this.total.third = res.totalElements;
      }
      if (this.type == "4") {
        this.news.fourth = result;
        this.total.fourth = res.totalElements;
      }
      if (this.type == "5") {
        this.news.five = result;
        this.total.five = res.totalElements;
      }
    },
    queryIntroduction: async function () {
      const res = await request({
        url: "/api/aboutIntroduction/queryIntroduction?page=0&size=1",
        method: "get",
      });
      if (getLang() == "en-gb") {
        this.introduction = res.content[0].introductionEn;
      } else if (getLang() == "de-de") {
        this.introduction = res.content[0].introductionDe;
      } else if (getLang() == "fr-fr") {
        this.introduction = res.content[0].introductionFr;
      } else if (getLang() == "ru") {
        this.introduction = res.content[0].introductionRu;
      } else if (getLang() == "nl") {
        this.introduction = res.content[0].introductionNl;
      } else if (getLang() == "pl") {
        this.introduction = res.content[0].introductionPl;
      } else if (getLang() == "ja") {
        this.introduction = res.content[0].introductionJp;
      } else {
        this.introduction = res.content[0].introductionCn;
      }
    },

    queryOurGameTextList: async function () {
      const res = await request({
        url: "/api/ourGameText/queryOurGameTextList?page=0&size=1",
        method: "get",
      });
      if (getLang() == "en-gb") {
        this.ourgame = res.content[0].descEn;
      } else if (getLang() == "de-de") {
        this.ourgame = res.content[0].descDe;
      } else if (getLang() == "fr-fr") {
        this.ourgame = res.content[0].descFr;
      } else if (getLang() == "ru") {
        this.ourgame = res.content[0].descRu;
      } else if (getLang() == "nl") {
        this.ourgame = res.content[0].descNl;
      } else if (getLang() == "pl") {
        this.ourgame = res.content[0].descPl;
      } else if (getLang() == "ja") {
        this.ourgame = res.content[0].descJp;
      } else {
        this.ourgame = res.content[0].descCn;
      }
    },
    queryHomePartnerList: async function () {
      const res = await request({
        url: "/api/homePartner/queryHomePartnerList?type=" + this.type + "&page=0&size=12isShow=1",
        method: "get",
      });
      var result = res.content;
      this.partner = result;
    },
  },
  mounted() {
    this.lang = getLang();
    this.queryIntroduction();
    this.queryOurGameTextList();
    this.queryHomePartnerList();
    this.queryNewsList(0);
    this.queryHomeBannerList();
    this.queryHomeOurGameList();
    this.oss = Config.oss;
  },
};
</script>

<style scoped>
.banner-info .pd {
  text-align: left;
  padding: 3rem 0;
}

.el-divider--horizontal {
  margin: 0;
  height: 2px;
}

.footer-left-line .line {
  background: linear-gradient(to right,
      rgba(38, 40, 47, 1),
      rgba(00, 180, 204, 1));
}

.footer-right-line .line {
  background: linear-gradient(to left,
      rgba(38, 40, 47, 1),
      rgba(00, 180, 204, 1));
}

.banner {
  background: linear-gradient(to right,
      rgba(27, 29, 34, 1),
      rgba(38, 40, 47, 0.6),
      rgba(27, 29, 34, 1));
  overflow: hidden;
}

.ourgamebackcolor {
  background: linear-gradient(to right,
      rgba(27, 29, 34, 1),
      rgba(38, 40, 47, 0.6),
      rgba(27, 29, 34, 1));
  padding: 6rem 0 6rem 0;
}

.video {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -9999;
  object-fit: cover;
}

::v-deep .el-tabs__active-bar {
  background: linear-gradient(to right,
      rgba(38, 40, 47, 1),
      rgba(00, 180, 204, 1));
}

::v-deep .el-carousel__container {
  min-height: 550px;
}

.item .el-row {
  background-color: #30343f;
  margin: 0.8rem 0;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.item .main-img {
  padding-left: 0 !important;
}

.item .main-img img {
  width: 100%;
  float: left;
  border-radius: 0.5rem;
  transition: 0.6s;
}

.item .main-img img:hover {
  transform: scale(1.1);
}

.main-text {
  padding: 0 1rem;
}

.item ul {
  padding: 0;
  margin: 0;
}

.item li {
  list-style: none;
  float: left;
  margin-right: 1rem;
  font-size: 0.8125rem;
  color: #ececed;
}

.item li img {
  margin-right: 0.3rem;
}

.item .title {
  line-height: 1.5;
  color: #ffffff;
  text-align: left;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: 3rem;
}

.item .date {
  color: #ececed;
  text-align: left;
  font-size: 0.8125rem;
  line-height: 3;
}

.item .game-item {
  padding-bottom: 1rem;
}

::v-deep .el-tabs__nav {
  float: right;
}

.introduction {
  color: #ffffff;
  text-align: left;
}

.banner {
  position: relative;
  background-size: cover;
}

.banner-info {
  padding: 4rem 0;
}

.banner-info h3 {
  text-align: left;
  color: #ffffff;
  font-size: 3rem;
  margin: 0;
  line-height: 1.5;
}

.banner-info h6 {
  text-align: left;
  color: #ffffff;
  font-size: 1rem;
  margin: 0;
  line-height: 1.5;
}

.banner-info li {
  list-style: none;
  float: left;
  padding-right: 0.5rem;
}

.platform-icon {
  overflow: hidden;
  text-align: left;
  padding: 0.5rem 0;
}

.platform-icon img {
  width: 24px;
}

.explore {
  padding: 0.8rem 6rem;
  cursor: pointer;
  font-size: 0.8rem;
  background-color: #00b4cc;
  border-radius: 0.3rem;
  border: none;
  color: #ffffff;
  font-weight: bold;
}

.news {
  padding: 3rem 0;
}

::v-deep .el-tabs__item {
  color: #ffffff;
  font-size: 0.8125rem;
}

.news-title .title,
.partner .title,
.about .title {
  font-size: 1.8rem;
  color: #ffffff;
}

.more {
  color: #ffffff;
  margin-left: 0.5rem;
  font-size: 0.8125rem;
}

.more i {
  color: #00b4cc;
  font-size: 0.8125rem;
}

.game-list {
  padding: 1rem 0;
}

.partner {
  background-color: #26282f;
  border-radius: 5rem;
  padding: 3rem 0 4rem 0;
  box-shadow: 0px 10px 10px #1b1d22;
  z-index: 1;
}

::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
  background-color: #2e323c;
}

.partner img {
  width: 80%;
}

.partner-link {
  padding-top: 2rem;
}

.ourgame {
  background-size: 100% 100%;
  margin-top: -3rem;
}

.ourgameimg {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.ourgame img {
  width: 100%;
  float: left;
}

.game-title {
  font-size: 1.8rem;
  color: #ffffff;
  line-height: 1.8;
  text-align: left;
}

.game-title .our {
  padding-bottom: 15px;
}

.game-title .line {
  border-bottom: 4px solid;
  border-image: linear-gradient(to right, #1b1d22, #01b1c9) 1 10;
  width: 6rem;
}

.game-title .game {
  margin-left: 1rem;
  display: inline-block;
}

.ourgame .right {
  padding-left: 3rem;
  text-align: left;
}

.game-desc {
  color: #ffffff;
  line-height: 1.5;
  padding-top: 1rem;
  text-align: left;
}

.more-games {
  padding: 0.8rem 3rem;
  cursor: pointer;
  font-size: 0.8rem;
  background-color: #00b4cc;
  border-radius: 0.3rem;
  border: none;
  color: #ffffff;
  font-weight: bold;
  margin-top: 2rem;
}

.about {
  padding: 4rem 0 1rem 0;
  background-image: url("~@/assets/about-us.png");
  background-size: 100% 100%;
  margin-top: -4rem;
}

.about .text {
  font-size: 1.8rem;
  color: #ffffff;
  text-align: left;
  padding: 0.5rem 0;
}

.about .title {
  color: #aadbe2;
}

.about p {
  color: #ffffff;
  text-align: left;
}

.about img {
  width: 100%;
}

.game-img img {
  width: 55%;
  margin-bottom: 3rem;
}

.news-title {
  text-align: left;
  z-index: 99;
}

@media screen and (min-width: 1200px) {
  .game-img img {
    width: 50%;
  }

  .partner-link .el-col-lg-6 {
    width: 20%;
    text-align: center;
  }

  .partner-link .el-col-lg-6:first-child {
    text-align: left;
  }

  .partner-link .el-col-lg-6:last-child {
    text-align: right;
  }
}

@media screen and (max-width: 992px) {
  * {
    flex-wrap: wrap;
  }

  .partner-link img {
    padding: 1rem 0;
  }

  .banner-info {
    padding: 0;
  }

  .banner-info h3 {
    font-size: 2rem;
  }

  .banner-info .pd {
    padding: 2rem 0;
  }

  .about {
    background-image: url("~@/assets/home-about.png");
  }

  .game-list {
    display: flex;
    justify-content: center;
  }
}


@media screen and (min-width: 992px) {
  ::v-deep .el-tabs__header {
    padding-left: 250px;
  }

  .news-title {
    position: absolute;
  }
}
</style>