<template>
  <el-row>
    <el-row class="footer" justify="center" type="flex">
      <el-col :lg="16" :md="22" :sm="22" :xs="22">
        <el-row :gutter="15">
          <el-col :lg="6" :md="6" :sm="22" :xs="22">
            <img :src="img(logo, lang)" class="logo" />
          </el-col>
          <el-col :lg="9" :md="9" :sm="24" :xs="24" style="padding-bottom: 1rem" v-for="(item, index) in officeInfo"
            :key="index">
            <div class="office-right">
              <div class="office">{{ item.place }}</div>
              <div class="address">
                {{ item.address }}
              </div>
              <div class="email" v-show="item.email != null">{{ item.email }}</div>
              <div class="phone" v-show="item.phone != null">{{ item.phone }}</div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="copyright" justify="center" type="flex">
      <el-col :lg="16" :md="22" :sm="22" :xs="22">
        <el-row>
          <el-col :lg="16" :md="16" :sm="24" :xs="24" class="text">@Copyright {{ text("copyright", lang) }}
            &nbsp;&nbsp;<a href="https://beian.miit.gov.cn/">陕ICP备16005864号-2</a>&nbsp;&nbsp; <a
              href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61019102000337">陕公网安备
              61019102000337号</a></el-col>
          <el-col :lg="8" :md="8" :sm="24" :xs="24">
            <div class="social-share" data-initialized="true">
              <a href="#" class="social-share-icon icon-wechat" v-show="lang == 'zh-cn'"></a>
              <a href="#" class="social-share-icon icon-weibo" v-show="lang == 'zh-cn'"></a>
              <a href="#" class="social-share-icon icon-qq" v-show="lang == 'zh-cn'"></a>
              <a href="#" class="social-share-icon icon-qzone" v-show="lang == 'zh-cn'"></a>
              <a href="#" class="social-share-icon icon-linkedin" v-show="lang != 'zh-cn'"></a>
              <a href="#" class="social-share-icon icon-facebook" v-show="lang != 'zh-cn'"></a>
              <a href="#" class="social-share-icon icon-twitter" v-show="lang != 'zh-cn'"></a>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import request from "@/utils/request";
import { getLang, getText, getImg } from "@/utils/lang";

export default {
  name: "Footer",
  props: {},
  data() {
    return {
      officeInfo: [],
      lang: "en-gb",
      logo: [
        { img: require("@/assets/logo-cn.png"), text: "简体中文", lang: "zh-cn" },
        { img: require("@/assets/logo-en.png"), text: "English", lang: "en-gb" },
        { img: require("@/assets/logo-en.png"), text: "Deutsch", lang: "de-de" },
        { img: require("@/assets/logo-en.png"), text: "Francais", lang: "fr-fr" },
        { img: require("@/assets/logo-en.png"), text: "Pyccknn", lang: "ru" },
        { img: require("@/assets/logo-en.png"), text: "Dutch", lang: "nl" },
        { img: require("@/assets/logo-en.png"), text: "Polish", lang: "pl" },
        { img: require("@/assets/logo-en.png"), text: "Japanese", lang: "ja" },
      ],
    };
  },
  methods: {
    text: function (text, lang) {
      return getText(text, lang);
    },
    img: function (img, lang) {
      return getImg(img, lang);
    },
    share: function () {
      var $config = {
      };
      socialShare('.social-share', $config);
    },
    queryOfficeInfo: async function () {
      const res = await request({
        url: "/api/officeInfo/queryOfficeInfoList?page=0&size=999&sort=sort,asc",
        method: "get",
      });
      this.lang = getLang();
      var result = [];
      for (var i = 0; i < res.content.length; i++) {
        var item = res.content[i];
        if (this.lang == "en-gb") {
          var data = {
            place: item.placeEn,
            address: item.addressEn,
            email: item.email,
            phone: item.phone,
          };
          result[i] = data;
        } else {
          var data = {
            place: item.placeCn,
            address: item.addressCn,
            email: item.email,
            phone: item.phone,
          };
          result[i] = data;
        }
      }
      this.officeInfo = result;
    },
  },
  mounted() {
    this.queryOfficeInfo();
    this.share();
    this.lang = getLang();
    request({
      url: "/api/accessLog/access",
      method: "post",
      data: { page: this.$route.name },
    });
  },
};
</script>

<style scoped>
.copyright .social-share {
  text-align: right;
}

.copyright .text,
.copyright .text a {
  color: #ffffff;
}

.office-right {
  display: inline-block;
  float: right;
}

.logo {
  width: 90%;
  position: relative;
  top: 3rem;
}

.footer {
  text-align: left;
  color: #e1e1e1;
  padding: 3rem 0;
  font-size: 0.8125rem;
  line-height: 1.5;
  background-color: #1b1d22;
}

.copyright {
  background-color: #26282f;
  font-size: 0.8125rem;
  color: #d7d7d8;
  padding: 0.6rem 0 0.4rem 0;
}

.copyright div {
  line-height: 42px;
  text-align: left;
}

.office {
  color: #ffffff;
  font-size: 1.5rem;
  line-height: 1.5;
  padding-bottom: 1rem;
}

.copyright ul {
  overflow: hidden;
  padding: 0;
  margin: 0;
  float: right;
}

.copyright li {
  list-style: none;
  float: left;
  margin-left: 1rem;
}

.address {
  background-image: url("~@/assets/address.svg");
  background-repeat: no-repeat;
  padding-left: 2rem;
  padding-bottom: 0.5rem;
  background-size: 1.2rem;
}

.email {
  background-image: url("~@/assets/email.svg");
  background-repeat: no-repeat;
  padding-left: 2rem;
  padding-bottom: 0.5rem;
  background-size: 1.2rem;
}

.phone {
  background-image: url("~@/assets/phone.svg");
  background-repeat: no-repeat;
  padding-left: 2rem;
  padding-bottom: 0.5rem;
  background-size: 1.2rem;
}

.copyright img {
  width: 24px;
}

@media screen and (max-width: 992px) {
  * {
    flex-wrap: wrap;
  }

  .logo {
    width: 80%;
    top: 0;
    padding-bottom: 1rem;
    display: none;
  }

  .copyright .text {
    text-align: center;
  }

  .copyright .social-share {
    text-align: center;
  }

  .office-right {
    float: left;
  }
}
</style>